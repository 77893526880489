import React from "react"

import SEO from "../components/seo"

const Error = () => {
  return (
    <>
      <SEO title="Page Not Found" />
      <h1 className="error">Page not found</h1>
    </>
  )
}

export default Error
